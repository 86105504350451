import React from "react";
import "./kontakt.css";

const Kontakt = () => {
  return (
    <div>
      <h1>Kontakt</h1>
      <div className="kontakt">
        <div className="div1">
          <div>
            <h2>Sie können uns telefonisch erreichen:</h2>
          </div>
          <div className="oeffnungszeiten">
            <h3>Montag bis Freitag</h3>
            <p>08:00 bis 12:00 | 13:00 bis 17:00 Uhr</p>
            <h3>Samstag und Sonntag</h3>
            <p>Geschlossen</p>
          </div>
        </div>
        <div className="email">
          <h2>oder Sie kontaktieren uns per E-Mail:</h2>
          <p>Stellen Sie sicher, dass Sie ihren Wunsch in der E-Mail erwähnen.</p>
          <div>
          <a href= "mailto:info@elektro-krone.de"><button className="btn123">E-Mail senden</button></a>
          </div>
          <p>Ihre Emails werden während der Zeit bearbeitet, wo wir telefonisch erreichbar sind.</p>

        </div>
      </div>
    </div>
  );
};

export default Kontakt;
