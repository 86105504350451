import React from "react";
import "./impressum.css"; // Import your CSS file

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>

      <div className="impressum-card">
        <h2>Angaben gemäß § 5 TMG</h2>
        <address>
          <p>Serper Constantin</p>
          <p>Elektro Krone</p>
          <p>Buchenweg 10</p>
          <p>78239 Rielasingen-Worblingen</p>
        </address>
      </div>

      <div className="impressum-card">
        <h2>Kontakt</h2>
        <address>
          <p>Telefon: +49 176 432 618 60</p>
          <p>E-Mail: info@elektro-krone.de</p>
        </address>
      </div>

      <div className="impressum-card">
        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE356700553</p>
      </div>

      <div className="impressum-card">
        <h2>Gewerbeanmeldung</h2>
        <p>Die Gewerbeanmeldung nach § 14 oder § 55c GewO wurde am 06.10.2022 durch die Gemeinde Rielasingen-Worblingen erteilt.</p>
      </div>

      <div className="impressum-card">
        <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>Berufsbezeichnung: Elektrotechnikerhandwerk</p>
        <p>Zuständige Kammer: Handwerkskammer Konstanz, Webersteig 3, 78462 Konstanz, Deutschland</p>
        <p>Verliehen in: Deutschland</p>
      </div>

      <div className="impressum-card">
        <h2>Angaben zur Berufshaftpflichtversicherung</h2>
        <p><strong>Name und Sitz des Versicherers:</strong></p>
        <address>
          <p>SV SparkassenVersicherung</p>
          <p>Bueche u. Klein</p>
          <p>Industriestr. 21</p>
          <p>78224 Singen (Hohentwiel)</p>
        </address>
        <p><strong>Geltungsraum der Versicherung:</strong> Deutschland</p>
      </div>

      <div className="impressum-card">
        <h2>EU-Streitschlichtung</h2>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a className="impressum-link" href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
      </div>

      <div className="impressum-card">
        <h2 id="uebertitel">Verbraucherstreitbeilegung/<br></br>Universalschlichtungsstelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      </div>

      <div className="impressum-card">
        <h2>Haftung für Inhalte</h2>
        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
      </div>

      <div className="impressum-card">
        <h2>Haftung für Links</h2>
        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
      </div>

      <div className="impressum-card">
        <h2>Urheberrecht</h2>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        <p>Quelle: <a className="impressum-link" href="https://www.e-recht24.de">eRecht24</a></p>
      </div>
    </div>
  );
};

export default Impressum;
