import React, { useState } from "react";
import Slider from "../slider/slider"
import time from "../../images/time-management.png";
import quality from "../../images/high-quality.png";
import customer from "../../images/customer.png";
import innovation from "../../images/innovation.png";
import makeitwork from "../../images/flash.png";

import installation from "../../images/installationsbild.jpg";
import beleuchtung from "../../images/beleuchtung1.jpg";
import photov from "../../images/photovolatik.jpg"
import planung from "../../images/planung.jpg";
import schrank from "../../images/schrank2.jpg"

import video from "../../images/windrad.gif"

import "./dienstleistungen.css";

const Dienstleistungen = () => {
  const [toggle, setToggle] = useState(1);
  const [selectedButton, setSelectedButton] = useState(1);

  function updateToggle(id) {
    setToggle(id);
    setSelectedButton(id);
  }
  return (
    <div>
      <h1>Dienstleistungen</h1>
      <div className="mz-container">
        <div className="mz-1">
          <div className="picContainer">
            <img src={customer} alt="innovation"></img>
          </div>
          <div className="textContainer">
            <p>Verbindungen, die begeistern!</p>
          </div>
        </div>
        <div className="mz-2">
          <div className="picContainer">
            <img src={quality} alt="innovation"></img>
          </div>
          <div className="textContainer">
            <p>Elektrifizierte Qualität!</p>
          </div>
        </div>
        <div className="mz-3">
          <div className="picContainer">
            <img src={time} alt="innovation"></img>
          </div>
          <div className="textContainer">
            <p>Pünktliche Lösungen!</p>
          </div>
        </div>
        <div className="mz-4">
          <div className="picContainer">
            <img src={innovation} alt="innovation"></img>
          </div>
          <div className="textContainer">
            <p>Elektrik mit Innovation!</p>
          </div>
        </div>
        <div className="mz-5">
          <div className="picContainer">
            <img src={makeitwork} alt="innovation"></img>
          </div>
          <div className="textContainer">
            <p>Immer in Betrieb!</p>
          </div>
        </div>
      </div>
      <div className="dienstleistungen">
        <h1>Unser Service</h1>
        <div className="buttons">
          <button
            className={selectedButton === 1 ? "active" : ""}
            onClick={() => updateToggle(1)}
          >
            Elektroinstallationen
          </button>
          <button
            className={selectedButton === 2 ? "active" : ""}
            onClick={() => updateToggle(2)}
          >
            Beleuchtungen
          </button>
          <button
            className={selectedButton === 3 ? "active" : ""}
            onClick={() => updateToggle(3)}
          >
            Smarthome
          </button>
          <button
            className={selectedButton === 4 ? "active" : ""}
            onClick={() => updateToggle(4)}
          >
            Netzwerktechnik
          </button>
          <button
            className={selectedButton === 5 ? "active" : ""}
            onClick={() => updateToggle(5)}
          >
            Erneuerbare Energie + Nutzung
          </button>
          <button
            className={selectedButton === 6 ? "active" : ""}
            onClick={() => updateToggle(6)}
          >
            Sonstiges
          </button>
        </div>
        <div className={toggle === 1 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Elektroinstallationen</h2>
              <ul className="service-list">
                <li>Schaltschrankbau</li>
                <li>Zählerschranke</li>
                <li>elektrischer Anschluss von Wärmepumpen</li>
              </ul>
            </div>
            <div className="img-container">
              <img id="image" src={installation} alt="testetsef"></img>
            </div>
          </div>
        </div>
        <div className={toggle === 2 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Beleuchtungen</h2>
              <ul className="service-list">
                <li>Hängeleuchten</li>
                <li>Einbauspots</li>
                <li>Led Stripe</li>
              </ul>
            </div>
            <div className="img-container">
              <img id="image" src={beleuchtung} alt="testetsef223"></img>
            </div>
          </div>
        </div>
        <div className={toggle === 3 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Smarthome (Homematic IP)</h2>
              <ul className="service-list">
                <li>Funktechnik</li>
                <li>Bustechnik</li>
              </ul>
            </div>
            <div className="img-container">
              <Slider />
            </div>
          </div>
        </div>
        <div className={toggle === 4 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Netzwerktechnik</h2>
              <ul className="service-list">
                <li>Video- und Sprechanlagen</li>
                <li>Netzwerkverteilungen</li>
              </ul>
            </div>
            <div className="img-container">
              <img id="image" src={schrank} alt="test"></img>
            </div>
          </div>
        </div>
        <div className={toggle === 5 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Erneuerbare Energie + Nutzung</h2>
              <ul className="service-list">
                <li>Photovoltaikanlagen</li>
                <li>Wallbox Installation</li>
                <li>Dach-Windkrafträder</li>
              </ul>
            </div>
            <div className="img-container">
              <img id="image" src={photov} alt="testetsef2"></img>
              <img id="gif" src={video} alt="gif"></img>
            </div>
          </div>
        </div>
        <div className={toggle === 6 ? "show-content" : "hide-content"}>
          <div className="over-container">
            <div className="list">
              <h2>Sonstiges</h2>
              <ul className="service-list">
                <li>Elektrische Planung</li>
                <li>Anmeldung/Abmeldung beim Netzbetreiber</li>
                <li>Inbetriebnahme elektrischer Anlagen</li>
                <li>Fehlersuche</li>
              </ul>
            </div>
            <div className="img-container">
              <img id="image" src={planung} alt="testetsef"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dienstleistungen;
