import React, { useState } from "react";
import "./gallerie.css";
import Modal from "./modal";

import beleuchtung from "../../images/gallery/beleuchtung/beleuchtung1.jpg";
import beleuchtung2 from "../../images/gallery/beleuchtung/beleuchtung2.jpg";
import beleuchtung3 from "../../images/gallery/beleuchtung/beleuchtung3.jpg";
import beleuchtung4 from "../../images/gallery/beleuchtung/beleuchtung4.jpg";
import beleuchtung5 from "../../images/gallery/beleuchtung/beleuchtung5.jpg";
import beleuchtung6 from "../../images/gallery/beleuchtung/beleuchtung6.jpg";
import schrank1 from "../../images/gallery/Zählerschränke/schrank1.jpg";
import schrank2 from "../../images/gallery/Zählerschränke/schrank2.jpg";
import schrank3 from "../../images/gallery/Zählerschränke/schrank3.jpg";
import schrank4 from "../../images/gallery/Zählerschränke/schrank4.jpg";
import schrank5 from "../../images/gallery/Zählerschränke/schrank5.jpg";
import smart1 from "../../images/gallery/Smarthome/smart1.jpg";
import smart2 from "../../images/gallery/Smarthome/smart2.jpg";
import smart3 from "../../images/gallery/Smarthome/smart3.jpg";
import smart4 from "../../images/gallery/Smarthome/smart4.jpg";
import smart5 from "../../images/gallery/Smarthome/smart5.jpg";
import wind1 from "../../images/gallery/Windkrafträder/wind1.jpg";
import photovoltaik from "../../images/gallery/photovoltaik/photovoltaik.jpg";
import instal1 from "../../images/gallery/installation/instal1.jpg";
import instal2 from "../../images/gallery/installation/instal2.jpg";
import instal3 from "../../images/gallery/installation/instal3.jpg";
import instal4 from "../../images/gallery/installation/instal4.jpg";
import instal5 from "../../images/gallery/installation/instal5.jpg";
import instal6 from "../../images/gallery/installation/instal6.jpg";
import instal7 from "../../images/gallery/installation/instal7.jpg";
import instal8 from "../../images/gallery/installation/instal8.jpg";
import instal9 from "../../images/gallery/installation/instal9.jpg";
import bild1 from "../../images/gallery/bild2.jpg";
import bild2 from "../../images/gallery/bild4.jpg";
import sprech from "../../images/gallery/sprech.jpg";
import neubild1 from "../../images/gallery/neubild1.jpg";
import neubild2 from "../../images/gallery/neubild2.jpg";
import neubild3 from "../../images/gallery/neubild3.jpg";
import neubild4 from "../../images/gallery/neubild4.jpg";
import neubild5 from "../../images/gallery/neubild5.jpg";

const Gallerie = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    sprech,
    beleuchtung,
    beleuchtung2,
    beleuchtung3,
    beleuchtung4,
    beleuchtung5,
    beleuchtung6,
    schrank1,
    schrank2,
    schrank3,
    schrank4,
    schrank5,
    smart1,
    smart2,
    smart3,
    smart4,
    smart5,
    wind1,
    photovoltaik,
    instal1,
    instal2,
    instal3,
    instal4,
    instal5,
    instal6,
    instal7,
    instal8,
    instal9,
    bild1,
    bild2,
    neubild1,
    neubild2,
    neubild3,
    neubild4,
    neubild5,
  ];

  const openLightbox = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  const splitImagesIntoColumns = (images, columns) => {
    const result = [];
    const imagesPerColumn = Math.ceil(images.length / columns);

    for (let i = 0; i < columns; i++) {
      result.push(images.slice(i * imagesPerColumn, (i + 1) * imagesPerColumn));
    }

    return result;
  };

  const columns = splitImagesIntoColumns(images, 3);

  return (
    <div>
      <h1>Gallerie</h1>
      <div className="gallerie-container">
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className="gallery-section">
            {column.map((imageSrc, index) => (
              <img
                key={index}
                src={imageSrc}
                className="gallerie-image"
                alt={`Image ${index}`}
                onClick={() => openLightbox(imageSrc)}
                id="testest"
              />
            ))}
          </div>
        ))}
      </div>
      {selectedImage && (
        <Modal imageSrc={selectedImage} onClose={closeLightbox} />
      )}
    </div>
  );
};

export default Gallerie;
