import React from "react";
import logo from "../../images/logo.jpg";
import "./ueberuns.css";
import platzhalter from "../../images/platzhalter.webp";
import auto from "../../images/auto.jpg";

const Ueberuns = () => {

  return (
    <div>
      <h1>Über uns</h1>
      <div className="cover">
        <div className="left">
          <h2>Unser Unternehmen</h2>
          <p>
            Das Unternehmen "Elektro Krone" wurde im Jahr 2022 von Constantin
            Serper gegründet und hat seinen Firmensitz in der Buchenweg 10,
            78239 Rielasingen-Worblingen. Unser Team setzt sich wie folgt
            zusammen:
            <li>1 Elektroinstallateur</li>
            <li>1 Bauhelfer</li>
            <li>1 Bürokraft</li>
            <li>1 Elektromeister</li>
            Wir streben nach Perfektionismus, da dies dazu beiträgt, zufriedene
            Kunden zu gewinnen. Unter dem Motto "Nichts ist unmöglich" nehmen
            wir jede Herausforderung an und streben stets nach der bestmöglichen
            Lösung. Durch kontinuierliche Schulungen bleiben wir stets auf dem
            neuesten Stand der Technik und setzen uns fortlaufend für unsere
            Weiterentwicklung ein.
          </p>
        </div>
        <div className="right">
          <img id="logo" src={logo} alt="test" />
        </div>
      </div>
      <div className="history">
        <div className="bild">
          <img id="kostja" src={platzhalter} alt="test1"/>
          <h1>Constantin Serper</h1>
          <span id="meister">Elektromeister</span>
        </div>
        <div className="liste">
          <h2>ihr Elektromeister, mit Erfahrung in:</h2>
          <div className="card">
            <ul className="work-list">
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Beleuchtungen jeder Art</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Zählerschranke</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Unterverteiler</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Neu-/Umbau</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Schaltschränke</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Anlagenbau</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Smarthome</div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">
                  Installationspartner von 
                  <a href="https://homematic-ip.com/de"> Homematic IP</a>
                </div>
              </li>
              <li className="work-item">
                <svg
                  className="work-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11h-9V2H9v9H2v3h7v9h2v-9h9z" />
                </svg>
                <div className="work-title">Autorisierter Partner von <a href="https://doorbird.com/"> Doorbird</a></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="auto-body">
        <h1>Unser Geschäftsauto</h1>
        <img src={auto} alt="auto" id="auto"></img>
      </div>
    </div>
  );
};

export default Ueberuns;
