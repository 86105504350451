import React, { useEffect } from "react";
import "./homepage.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import beleuchtung from "../../images/beleuchtung2.jpg";
import smarthome from "../../images/homematic_smarthome.jpg";
import homematic from "../../images/Homematic_IP-Logo-75_grau.png";
import rarchitect from "../../images/AR-Architekten_WB-01_310820.png";
import sterne from "../../images/logo5Sterne-solar.png";
import schrank from "../../images/gallery/Zählerschränke/schrank2.jpg";
import pv from "../../images/gallery/photovoltaik/photovoltaik.jpg";
import blickfang from "../../images/blickfang.jpg";
import wultschner from "../../images/wultschner.png";
import doorbird from "../../images/doorbird-xxl.png";

const Homepage = () => {
  useEffect(() => {
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);
  return (
    <div>
      <div className="cover-container">
        <div className="cover1"></div>
        <div className="text">
          <h1 id="title">Willkommen bei der Elektro Krone</h1>
          <h2 className="cover-text">
            Wir bringen das <span>Licht</span>
          </h2>
        </div>
        <div className="cover2"></div>
      </div>
      <div className="catchphrase">
        <p>
          Sie haben <span>Probleme</span> mit ihrer Elektronik oder möchten
          etwas installieren, aber wissen nicht wie? <br />
          <span>Dann sind sie hier genau richtig!</span>
        </p>
        <a href="/dienstleistungen">
          <button className="service-btn">
            <span>Dienstleistungen</span>
            <svg
              width="34"
              height="34"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="37"
                cy="37"
                r="35.5"
                stroke="black"
                strokeWidth="3"
              ></circle>
              <path
                d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                fill="black"
              ></path>
            </svg>
          </button>
        </a>
      </div>
      <div className="showcase">
        <div className="smarthome">
          <a href="/dienstleistungen">
            <h2 id="asdf">Smarthome-Integration</h2>
            <img src={smarthome} alt=""></img>
          </a>
        </div>
        <div className="beleuchtung">
          <a href="/dienstleistungen">
            <h2 id="asdf">Beleuchtungsinstallation</h2>
            <img src={beleuchtung} alt=""></img>
          </a>
        </div>
        <div className="energie">
          <a href="/dienstleistungen">
            <h2 id="asdf">Versorgungstechnik</h2>
            <img src={pv} alt=""></img>
          </a>
        </div>
        <div className="umbau">
          <a href="/dienstleistungen">
            <h2 id="asdf">Umbau/Neubau-Elektrik</h2>
            <img src={schrank} alt=""></img>
          </a>
        </div>
      </div>
      <div className="partners">
        <div className="text-row">
          <h1 id="jklo">Unsere Partner</h1>
        </div>
        <Splide
          aria-label="our offers"
          options={{
            type: "loop",
            interval: 1500,
            autoplay: true,
            speed: 3000,
            perMove: 1,
            rewind: true,
            perPage: window.innerWidth <= 760 ? 2 : 5,
            width: "95%",
            arrows: false,
            pagination: false,
          }}
        >
          <SplideSlide>
            <img src={homematic} alt="homematic" />
          </SplideSlide>
          <SplideSlide>
            <img src={rarchitect} alt="rarchitect" />
          </SplideSlide>
          <SplideSlide>
            <img src={blickfang} alt="blickfang" id="blickfang"/>
          </SplideSlide>
          <SplideSlide>
            <img src={sterne} alt="sterne" />
          </SplideSlide>
          <SplideSlide>
            <img src={wultschner} alt="wultschner" />
          </SplideSlide>
          <SplideSlide>
            <img src={doorbird} alt="doorbird" />
          </SplideSlide>
          </Splide>
      </div>
    </div>
  );
};

export default Homepage;
