import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className={`footer`}></footer>
      <div className="layout">
        <div className="lefty">
          <div className="tele">
            <h2>Kontakt</h2>
            <p>Telefon: +49 17643261860</p>
            <p>Email: info@elektro-krone.de</p>
            <p>Addresse:</p>
            <p>Buchenweg 10,</p>
            <p>78239 Rielasingen-Worblingen</p>
          </div>
        </div>
        <div className="middy">
          <div className="offen">
            <h2>Öffnungszeiten</h2>
            <h4>Montag bis Freitag</h4>
            <p>08:00 bis 12:00 | 13:00 bis 17:00 Uhr</p>
            <h4>Samstag und Sonntag</h4>
            <p>Geschlossen</p>
          </div>
        </div>
        <div className="righty">
          <div className="links-container">
            <h2>Links</h2>
            <div className="links">
              <a href="/gallerie">Gallerie</a>
              <a href="/ueberuns">Über uns</a>
              <a href="/kontakt">Kontakt</a>
              <a href="/dienstleistungen">Dienstleistungen</a>
              <a href="/impressum">Impressum</a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-bar">
        &#169; 2024 Elektrokrone. All Rights Reserved.
      </div>
    </React.Fragment>
  );
};

export default Footer;
