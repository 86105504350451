import React, { useState, useEffect } from "react";
import logo  from "../../images/logo.jpg"
import "./navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScrolling = () => {
      if (isMenuOpen) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    };

    handleScrolling();

    window.addEventListener("scroll", handleScrolling);

    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, [isMenuOpen]);
  return (
    <nav className={`navbar ${isMenuOpen ? "open" : ""}`}>
      <div className="logo">
        <a href="/"><img src={logo} alt="Logo" /></a>
      </div>
      <div
        className={`burger ${isMenuOpen ? "open" : ""}`}
        onClick={handleBurgerClick}
      >
          <div className="burger-line" />
          <div className="burger-line" />
          <div className="burger-line" />
      </div>
      <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
        <li>
          <a href="/dienstleistungen">Dienstleistungen</a>
        </li>
        <li>
          <a href="/ueberuns">Über uns</a>
        </li>
        <li>
          <a href="/gallerie">Gallerie</a>
        </li>
        <li>
          <a href="/kontakt">Kontakt</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
