import React from "react";
import pic1 from "../../images/smarthome/Android_Einstellungen.png";
import pic2 from "../../images/smarthome/Android_Homescreen.png";
import pic3 from "../../images/smarthome/Android_Info-und-Hilfe.png";
import pic4 from "../../images/smarthome/Android_Menue1.png";
import pic5 from "../../images/smarthome/Android_Raummenue.png";
import pic6 from "../../images/smarthome/iOS_Allgemeine_Einstellungen.png";
import pic7 from "../../images/smarthome/iOS_Geraeteinformationen.png";
import pic8 from "../../images/smarthome/iOS_Homescreen.png";
import pic10 from "../../images/smarthome/iOS_Menue1.png";
import pic11 from "../../images/smarthome/iOS_Reichweitenverlaengerung1.png";
import pic12 from "../../images/smarthome/iOS_Startbildschirm.png";
import SimpleImageSlider from "react-simple-image-slider";
import "./slider.css"; 

const images = [
  { url: pic1 },
  { url: pic2 },
  { url: pic3 },
  { url: pic4 },
  { url: pic5 },
  { url: pic6 },
  { url: pic7 },
  { url: pic8 },
  { url: pic12 },
  { url: pic10 },
  { url: pic11 },
];

const Slider = () => {
  return (
    <div className="custom-slider">
      <SimpleImageSlider
        width={300}
        height={600}
        borderRadius={33}
        images={images}
        showBullets={true}
        showNavs={false}
        autoPlay={true}
      />
    </div>
  );
};

export default Slider;
