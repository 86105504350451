import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Homepage from "./components/homepage/homepage";
import Kontakt from "./components/kontakt/kontakt";
import Gallerie from "./components/gallerie/gallerie";
import Ueberuns from "./components/ueberuns/uberuns";
import Anker from "./components/anker/anker";
import Dienstleistungen from "./components/dienstleistungen/dienstleistungen";
import Impressum from "./components/impressum/impressum";

const App = () => {
  return (
    <div>
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/gallerie" element={<Gallerie />} />
          <Route path="/ueberuns" element={<Ueberuns />} />
          <Route path="/dienstleistungen" element={<Dienstleistungen />} />
          <Route path="/impressum" element={<Impressum/>}/>
        </Routes>
      </Router>
      <Anker />
      <Footer />
    </div>
  );
};

export default App;
