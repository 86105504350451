import React from "react";
import "./modal.css";

const Modal = ({ imageSrc, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content">
        <img src={imageSrc} alt="Modal" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
